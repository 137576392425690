import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Cookies, withCookies } from 'react-cookie';
import find from 'lodash/find';
import matchesProperty from 'lodash/matchesProperty';
import { fetchSiteHeaderAction } from '../../../store/siteDucks/siteHeader';
import { fetchSiteContentAction } from '../../../store/siteDucks/siteContent';

import gtm from '../../../utils/initialGTM';
import Link from '../../shared/Link/Link';
import Modal from '../../shared/Modal/Modal';
import ModalPromptMA from '../../shared/ModalPromptMA/ModalPromptMA';

const X_MA_SEGMENTS = 'x-ma-segments';
const TUMGM = 'x-core-prompt';

export class Prompt extends Component {
  constructor(props) {
    super(props);

    const promptSections = find(
      props.siteContent.data.sections,
      matchesProperty('type', 'event_prompt_section'),
    );
    const promptId = `${this.stringToHash(
      promptSections?.header ?? 'default',
    )}`;
    this.state = {
      isDisplayed: false,
      promptId,
      promptSections,
    };

    this.sendMASegment = this.sendMASegment.bind(this);
  }

  componentDidMount() {
    const { cookies } = this.props;
    const previouslySubmittedHash = cookies.get(TUMGM);
    if (
      this.state.promptSections &&
      (!previouslySubmittedHash ||
        previouslySubmittedHash !== this.state.promptId)
    ) {
      const segmentsString = cookies.get(X_MA_SEGMENTS);
      this.showPopupIfNeeded(segmentsString?.split('|'));
    }
  }

  sendMASegment(segment) {
    const { cookies, pageConfig: { isPreview } = {} } = this.props;
    this.setCookies(this.state.promptId, TUMGM);
    this.setState({ isDisplayed: false });

    if (segment === 'ignore') {
      return;
    }

    const cookieSegments = cookies.get(X_MA_SEGMENTS);

    const segments = cookieSegments ? cookieSegments.split('|') : [];
    if (!segments.includes(segment)) {
      segments.push(segment);
      this.setCookies(segment, X_MA_SEGMENTS);
      this.fetchSiteData(segments.join('|'));
    }

    if (!isPreview) {
      gtm.updateSegmentsLayer(segment);
    }
  }

  stringToHash(string) {
    return string.split('').reduce((hash, char) => {
      return char.charCodeAt(0) + (hash << 6) + (hash << 16) - hash;
    }, 0);
  }

  fetchSiteData(segment) {
    const { fetchSiteHeader, fetchSiteContent } = this.props;
    const requestObject = {
      maSegments: { 'x-ma-segments': segment },
      sitePage: 'home',
    };

    fetchSiteHeader(requestObject);
    fetchSiteContent(requestObject);
  }

  setCookies(section, name) {
    const { pageConfig: { cookieSitePath } = {}, cookies } = this.props;
    const distance = new Date(Date.now() + 31622400000); // 366 * 24 * 60 * 60 * 1000 = around one year

    cookies.set(name, section, {
      expires: distance,
      path: cookieSitePath,
    });
  }

  showPopupIfNeeded(segments) {
    const siteSegments = this.state.promptSections?.prompts.map(
      (seg) => seg.name,
    );
    if (
      siteSegments &&
      (!segments || !siteSegments.some((s) => segments.includes(s)))
    ) {
      this.setState({ isDisplayed: true });
    }
  }

  render() {
    const { children = {} } = this.props;
    const { promptSections, isDisplayed } = this.state;

    const PromptModal =
      promptSections &&
      Modal(Link, ModalPromptMA, {}, 'white-overlay promptMA');

    return (
      <>
        {children}
        {promptSections && (
          <PromptModal
            isShowing={isDisplayed}
            superModal
            content={{
              content: promptSections,
              sendMASegment: this.sendMASegment,
            }}
          />
        )}
      </>
    );
  }
}

Prompt.propTypes = {
  options: PropTypes.shape({
    data: PropTypes.shape({
      maConfig: PropTypes.object,
      eventId: PropTypes.string,
    }),
  }),
  pageConfig: PropTypes.shape({
    isPreview: PropTypes.bool,
    tenantId: PropTypes.string,
    sitePath: PropTypes.string,
  }),
  siteContent: PropTypes.shape({
    data: PropTypes.shape({
      sections: PropTypes.array,
    }),
  }),
  children: PropTypes.node,
  cookies: PropTypes.instanceOf(Cookies),
  history: PropTypes.object,
  fetchSiteContent: PropTypes.func,
  fetchSiteHeader: PropTypes.func,
  sendChosenSegments: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    siteContent: state.siteContent,
    pageConfig: state.pageConfig,
    options: state.options,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchSiteContent(payload) {
      dispatch(fetchSiteContentAction(payload));
    },
    fetchSiteHeader(payload) {
      dispatch(fetchSiteHeaderAction(payload));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withCookies(Prompt)));
